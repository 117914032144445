<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-30 09:05:54
 * @LastEditors: ------
 * @LastEditTime: 2021-08-09 16:01:23
-->

<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-24 09:12:25
 * @LastEditors: ------
 * @LastEditTime: 2021-06-30 09:02:12
-->
<template>
  <div class="big_box">
    <TitleNav class=" ">
      <template #one>
        <span @click="go('home')" class="cursor">
          首页 >
        </span>
      </template>
      <template #two>
        <span @click="go('infoOne')" class="cursor">
          客户本人信息 >
        </span>
      </template>
      <template #three>
        <span class="cursor">
          家庭成员信息
        </span>
      </template>
      <template v-slot:slotCon>
        <div class="smallBox bg_white_color">
          <div class="box_left">
            <div class="left_top">
              <img src="../image/family.png" alt="" />
              <span class="base_333_color">家庭成员</span>
            </div>
            <div class="left_bot left_position">
              <div class="text_box">
                <div>家属标签：</div>

                <a-select default-value="请选择" @change="handleChange">
                  <a-select-option value="10">
                    父亲
                  </a-select-option>
                  <a-select-option value="11">
                    母亲
                  </a-select-option>
                  <a-select-option value="12">
                    爱人
                  </a-select-option>
                  <a-select-option value="19">
                    兄妹
                  </a-select-option>

                  <a-select-option value="14">
                    儿子
                  </a-select-option>
                  <a-select-option value="15">
                    女儿
                  </a-select-option>
                  <a-select-option value="18">
                    朋友
                  </a-select-option>
                </a-select>
              </div>
              <div class="text_box">
                <div>家属姓名：</div>
                <input
                  placeholder="请输入用户姓名"
                  class="userName"
                  v-model="name"
                  ref="userName"
                />
              </div>
              <div class="text_box">
                <div>家属手机号：</div>
                <input
                  placeholder="请输入用户手机号"
                  type="text"
                  class="userName"
                  v-model="number"
                  ref="Number"
                />
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="box_right right_position">
            <!-- <div class="left_top line_top">
             
            </div> -->
            <div class="left_bot right50">
              <div class="text_box">
                <div>家属身份证：</div>
                <input
                  placeholder="请输入用户身份证号"
                  class="userName"
                  v-model="id"
                  ref="id"
                />
              </div>
              <div class="text_box">
                <div>所在城市：</div>

                <a-cascader
                  size="large"
                  :options="options"
                  @change="onChange"
                  placeholder="省/市/区"
                ></a-cascader>
              </div>
              <div class="text_box">
                <div>居住地址：</div>
                <input
                  placeholder="请输入服务对象居住地址"
                  class="userName"
                  v-model="ServeAddress"
                  ref="ServeAddress"
                />
              </div>
            </div>
          </div>
          <button class="nextPage1 cursor base_white_color" @click="goBack">
            返回
          </button>
          <button class="nextPage cursor base_white_color" @click="nextPage">
            保存客户信息
          </button>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn ">
      <template #one>
        <button @click="go" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>
      <template #two>
        <button @click="go" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽
import city from "../assets/pca-code.json";
import { getItem } from "../utils/storage";
import { consumerInfo } from "../api/api";

export default {
  data() {
    return {
      name: "",
      number: "",
      id: "",
      options: null,
      ServeAddress: "",
      value: [],
      familyLabel: "",
      consumerFamilyMemberList: {
        kinship: "",
        name: "",
        idCard: "",
        mobile: "",
        province: "",
        city: "",
        county: "",
        detailAddress: "",
        street: "",
      },
      infos: null,
      nation: "",
    };
  },
  created() {
    this.options = city;
    this.infos = getItem("consumer");
    console.log(this.infos);
  },
  components: { TitleNav, ebutton },
  methods: {
    handleChange(value) {
      console.log(`selected ${value}`);
      this.familyLabel = value;
      this.consumerFamilyMemberList.kinship = value;
    },
    onChange(value, selectedOptions) {
      const select = selectedOptions.map((item) => item.label);
      this.value = select;
      console.log(value, select);
      this.consumerFamilyMemberList.province = value[0];
      this.consumerFamilyMemberList.city = value[1];
      this.consumerFamilyMemberList.county = value[2];
      this.nation = select[0] + select[1] + select[2];
    },
    go(key) {
      switch (key) {
        case "home":
          this.$router.push("/home");
          break;
        case "infoOne":
          this.$router.push("/infoOne");
          break;
        default:
          break;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    nextPage() {
      let that = this;
      if (that.familyLabel == "") {
        //校验家属标签
        that.$message.warning("请选择家属标签!");
        return false;
      }
      if (that.name == "") {
        //校验姓名
        that.$refs.userName.placeholder = "不能为空";
        that.$refs.userName.style.border = "1px solid  #ff9158";
        return false;
      } else if (that.name != "") {
        that.$refs.userName.style.border = "";
        that.consumerFamilyMemberList.name = that.name;
      }
      if (that.number == "") {
        //校验手机号
        that.$refs.Number.placeholder = "不能为空";
        that.$refs.Number.style.border = "1px solid  #ff9158";
        return false;
      } else if (that.number != "") {
        let phone = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (phone.test(that.number) == true) {
          that.$refs.Number.style.border = "";
          that.consumerFamilyMemberList.mobile = that.mobile;
        } else {
          that.number = "";
          that.$refs.Number.placeholder = "手机号错误";
          that.$refs.Number.style.border = "1px solid  #ff9158";
          return false;
        }
      }
      if (that.id.length != 18) {
        //校验身份证
        that.$refs.id.placeholder = "身份证错误";
        that.$refs.id.style.border = "1px solid #ff9158";
        return false;
      } else if (that.id.length == 18) {
        that.$refs.id.style.border = "";
        that.consumerFamilyMemberList.idCard = that.id;
      }
      if (that.value.length == 0) {
        //校验所在城市
        that.$message.warning("请选择所在城市!");
        return false;
      }
      if (that.ServeAddress == "") {
        //校验服务对象住址
        that.$refs.ServeAddress.placeholder = "不能为空";
        that.$refs.ServeAddress.style.border = "1px solid  #ff9158";
        return false;
      } else if (that.ServeAddress != "") {
        that.$refs.ServeAddress.style.border = "";
        that.consumerFamilyMemberList.street = that.ServeAddress;
      }
      that.consumerFamilyMemberList.detailAddress =
        that.nation + that.ServeAddress;

      that.infos.consumerFamilyMemberList[0] = that.consumerFamilyMemberList;
      that.$store.commit("setConsumer", that.infos);
      consumerInfo(that.infos).then((res) => {
        console.log(res);
        if (res.data.success) {
          that.$confirm({
            title: "保存成功!",
            content: res.data.message,
            okText: "确认",
            cancelText: "取消",
            onOk() {
              that.$router.push("/personal");
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        } else {
          that.$error({
            title: "保存失败!",
            content: res.data.message,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.right_position {
  margin-top: 118px;
}
.left_position {
  margin-top: 146px;
}
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
/* -------------------------------------------------------------------------- */
.box_left {
  width: 610px;
}
.box_right {
  width: 600px;
}

:deep(.ant-cascader-picker) {
  width: 372px;
  padding: 6px 0;
  height: 48px;
  font-size: 20px;
  color: #666666;
}
:deep(.ant-cascader-picker:hover input) {
  border: 1px solid var(--themeColor);
}
:deep(.ant-cascader-picker::after) {
  content: "";
  display: inline-block;
  height: 48px;
  width: 48px;
  background-color: rgba(204, 204, 204, 0.575);
  position: absolute;
  right: 0px;
  border-radius: 8px;
  margin-top: -8px;
}
:deep(.ant-cascader-picker::before) {
  position: absolute;
  content: "";
  height: 0px;
  width: 0px;
  border: 8px solid;
  border-color: #ccc transparent transparent transparent;
  right: 16px;
  top: 18px;
}
:deep(.ant-cascader-picker input) {
  height: 48px;
  margin-top: -8px;
  border-radius: 8px;
  font-size: 20px;
}
:deep(.ant-cascader-picker:focus .ant-cascader-input) {
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}

:deep(.ant-input::placeholder) {
  font-size: 20px;
  height: 48px;
  padding: 8px;
  color: #666;
}
:deep(.ant-cascader-picker-label) {
  padding: 0px 48px 0px 21px;
  font-size: 20px;
  margin-top: -12px;
}
:deep(.ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: var(--themeColor);
}
:deep(.ant-cascader-picker:focus .ant-cascader-input) {
  border-color: var(--themeColor);
}
:deep(.ant-input:focus) {
  border: 1px solid var(--themeColor);
  box-shadow: none;
}
:deep(svg) {
  display: none;
}
:deep(.anticon) {
  display: none;
}

.smallBox {
  width: 1520px;
  height: 606px;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  padding: 40px 46px;
  display: flex;
  justify-content: space-between;
}
.line {
  height: 256px;
  border-left: 1px solid #e2e2e2;
  margin-top: 125px;
}
.left_top img {
  width: 23px;
  height: 23px;
  margin-right: 18px;
  vertical-align: sub;
}
.left_top span {
  font-size: 22px;
}
.left_top {
  position: absolute;
  padding-bottom: 32px;
  margin-bottom: 78px;
  width: 1427px;
  border-bottom: 1px solid #e2e2e2;
}
.left_bot {
  margin-left: 50px;
  width: 553px;
  font-size: 20px;
}
.right50 {
  margin-left: 0px;
}
.left_bot > div {
  display: flex;
  justify-content: space-between;
}
input::placeholder {
  font-size: 20px;
  color: #cacaca;
}
input {
  outline: none;
  border: none;
}

.userName {
  width: 372px;
  height: 48px;
  border-radius: 8px;
  line-height: 48px;
  padding-left: 20px;
  border: 1px solid #ccc;
}

input:focus {
  border: none;
  box-shadow: 0px 0px 3px var(--themeColor);
  border: 1px solid var(--themeColor);
}
input:hover {
  border: 1px solid var(--themeColor);
}
.text_box {
  height: 48px;
  line-height: 48px;
  margin-top: 28px;
  position: relative;
}
.nextPage {
  width: 257px;
  height: 64px;
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  border-radius: 10px;
  position: absolute;
  bottom: 192px;
  left: 890px;
  font-size: 20px;
}
.nextPage1 {
  width: 257px;
  height: 64px;
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
  border-radius: 10px;
  position: absolute;
  font-size: 20px;
  bottom: 192px;
  left: 580px;
}
:deep(.ant-select) {
  width: 372px;
  height: 48px !important;
}
:deep(.ant-select-selector) {
  height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid #ccc !important;
}
:deep(.ant-select-selector::before) {
  content: "";
  height: 48px;
  width: 48px;
  background-color: rgba(204, 204, 204, 0.527);
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: -1px;
}
:deep(.ant-select-selection-item::before) {
  position: absolute;
  content: "";
  height: 0px;
  width: 0px;
  border: 8px solid;
  border-color: #ccc transparent transparent transparent;
  right: 5px;
  top: 18px;
}
:deep(.ant-select-selector:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  border: 1px solid var(--themeColor) !important;
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}
:deep(.ant-select-selection-item) {
  height: 48px !important;
  line-height: 46px !important;
  font-size: 20px !important;
  margin-left: 8px;
}
:deep(.ant-select-arrow) {
  display: none;
}
</style>
